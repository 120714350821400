import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '1, 87, 155',
		'primary-dark': '0, 47, 108',
		'accent': '223, 167, 7',
		'accent-plus': '0, 0, 0',
	},
});
